exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-static-pages-comfortworld-co-uk-campaign-experientialfragrance-tsx": () => import("./../../../src/static-pages/comfortworld.co.uk/campaign/experientialfragrance.tsx" /* webpackChunkName: "component---src-static-pages-comfortworld-co-uk-campaign-experientialfragrance-tsx" */),
  "component---src-static-pages-comfortworld-co-uk-campaign-homebargainspeonycompetition-tsx": () => import("./../../../src/static-pages/comfortworld.co.uk/campaign/homebargainspeonycompetition.tsx" /* webpackChunkName: "component---src-static-pages-comfortworld-co-uk-campaign-homebargainspeonycompetition-tsx" */),
  "component---src-static-pages-comfortworld-co-uk-campaign-spinthewheel-tsx": () => import("./../../../src/static-pages/comfortworld.co.uk/campaign/spinthewheel.tsx" /* webpackChunkName: "component---src-static-pages-comfortworld-co-uk-campaign-spinthewheel-tsx" */),
  "component---src-static-pages-comfortworld-co-uk-campaign-winterindulgencecompetition-tsx": () => import("./../../../src/static-pages/comfortworld.co.uk/campaign/winterindulgencecompetition.tsx" /* webpackChunkName: "component---src-static-pages-comfortworld-co-uk-campaign-winterindulgencecompetition-tsx" */),
  "component---src-static-pages-comfortworld-co-uk-fragrancequiz-tsx": () => import("./../../../src/static-pages/comfortworld.co.uk/fragrancequiz.tsx" /* webpackChunkName: "component---src-static-pages-comfortworld-co-uk-fragrancequiz-tsx" */),
  "component---src-templates-contact-us-contact-us-tsx": () => import("./../../../src/templates/contactUs/ContactUs.tsx" /* webpackChunkName: "component---src-templates-contact-us-contact-us-tsx" */),
  "component---src-templates-faq-faq-tsx": () => import("./../../../src/templates/faq/FAQ.tsx" /* webpackChunkName: "component---src-templates-faq-faq-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../../../src/templates/home/Home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */),
  "component---src-templates-landing-page-landing-page-tsx": () => import("./../../../src/templates/landingPage/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-landing-page-tsx" */),
  "component---src-templates-message-message-tsx": () => import("./../../../src/templates/message/Message.tsx" /* webpackChunkName: "component---src-templates-message-message-tsx" */),
  "component---src-templates-sign-up-form-widget-sign-up-form-widget-success-page-tsx": () => import("./../../../src/templates/signUpFormWidget/SignUpFormWidgetSuccessPage.tsx" /* webpackChunkName: "component---src-templates-sign-up-form-widget-sign-up-form-widget-success-page-tsx" */),
  "component---src-templates-sign-up-form-widget-sign-up-form-widget-tsx": () => import("./../../../src/templates/signUpFormWidget/SignUpFormWidget.tsx" /* webpackChunkName: "component---src-templates-sign-up-form-widget-sign-up-form-widget-tsx" */),
  "component---src-templates-sitemap-sitemap-tsx": () => import("./../../../src/templates/sitemap/Sitemap.tsx" /* webpackChunkName: "component---src-templates-sitemap-sitemap-tsx" */),
  "component---src-templates-subject-subject-tsx": () => import("./../../../src/templates/subject/Subject.tsx" /* webpackChunkName: "component---src-templates-subject-subject-tsx" */),
  "component---src-templates-variant-variant-tsx": () => import("./../../../src/templates/variant/Variant.tsx" /* webpackChunkName: "component---src-templates-variant-variant-tsx" */)
}

